import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  Table,
  Space,
  Button,
  message,
  Image,
  Modal,
  Input,
  Timeline,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { helper } from "@src/controls/controlHelper";
import { formatMoney } from "../../utils";
import { returnOrderStatusEnum } from "@src/util/enums";
import {
  TableContainer,
  ProductInfo,
  ProductImage,
  ProductDetails,
  StatusTag,
  ProductList,
  DetailsContainer,
  Section,
  SectionTitle,
  ProductsList,
  ProductCard,
  ProductName,
  QuantityBadge,
  InfoGrid,
  InfoItem,
  Label,
  Value,
  ReasonTitle,
  ReasonDescription,
  ImageGrid,
  ReasonBox,
  ModalTitle,
} from "./style";

interface ReturnOrderItem {
  images: string[];
  name: string;
  quantity: number;
  itemPrice: number;
}

interface ReturnOrder {
  id: number;
  orderItemInfos: ReturnOrderItem[];
  totalMoneyReturn: number;
  title: string;
  description: string;
  images?: string[];
  status: keyof typeof returnOrderStatusEnum;
  returnShipment?: {
    // Receiver info
    receiveName: string;
    receivePhone: string;
    receiveAddress: string;

    // Delivery info
    deliveryName: string;
    deliveryPhone: string;
    deliveryAddress: string;

    // Shipping info
    shipMsg: {
      // Timestamp
      time: number;
      message: string;
    }[];
    shipCarrier: string;
    shipCarrierImage: string;
    shipServiceName: string;
  };
  returnMessages: {
    time: number;
    message: string;
  }[];
  storeInfo: {
    name: string;
    logo: string;
  };
  cancelRejectReason?: string;
}

interface ReturnOrderTableProps {
  pageId: number;
  filters: any;
  buildQueryParams: () => any;
  updatePagination: (current: number, pageSize: number) => void;
  onStatusCountsUpdate?: (counts: any) => void;
}

// Separate Modal Components
const ProductListModal = ({
  visible,
  products,
  onClose,
}: {
  visible: boolean;
  products: ReturnOrderItem[];
  onClose: () => void;
}) => (
  <Modal
    title="All Products"
    visible={visible}
    onCancel={onClose}
    footer={null}
    width={600}
  >
    <ProductList>
      {products.map((product, index) => (
        <ProductInfo key={index}>
          <ProductImage src={product.images[0]} />
          <ProductDetails>
            <div>{product.name}</div>
            <div>
              Quantity: x{product.quantity} - Price:{" "}
              {formatMoney(product.itemPrice * product.quantity)}
            </div>
          </ProductDetails>
        </ProductInfo>
      ))}
    </ProductList>
  </Modal>
);

const ActionConfirmModal = ({
  visible,
  actionType,
  loading,
  isReturningItems,
  cancelRejectReason,
  onClose,
  onConfirm,
  onReturningItemsChange,
  onReasonChange,
}: {
  visible: boolean;
  actionType: "accept" | "reject" | null;
  loading: boolean;
  isReturningItems: boolean;
  cancelRejectReason: string;
  onClose: () => void;
  onConfirm: () => void;
  onReturningItemsChange: (value: boolean) => void;
  onReasonChange: (value: string) => void;
}) => (
  <Modal
    title={
      actionType === "accept" ? "Accept Return Order" : "Reject Return Order"
    }
    visible={visible}
    onCancel={onClose}
    footer={[
      <Button key="cancel" onClick={onClose}>
        Cancel
      </Button>,
      <Button
        key="submit"
        danger={actionType === "reject"}
        type={actionType === "accept" ? "primary" : "default"}
        onClick={onConfirm}
        loading={loading}
      >
        Confirm
      </Button>,
    ]}
  >
    {actionType === "accept" && (
      <div style={{ marginBottom: 16 }}>
        <h4>Will the customer return the items?</h4>
        <Space>
          <Button
            type={isReturningItems ? "primary" : "default"}
            onClick={() => onReturningItemsChange(true)}
          >
            Yes, items will be returned
          </Button>
          <Button
            type={!isReturningItems ? "primary" : "default"}
            onClick={() => onReturningItemsChange(false)}
          >
            No return needed (gift)
          </Button>
        </Space>
      </div>
    )}
    {actionType === "reject" && (
      <>
        <p>Are you sure you want to reject this return order?</p>
        <Input.TextArea
          placeholder="Enter rejection reason"
          value={cancelRejectReason}
          onChange={(e) => onReasonChange(e.target.value)}
          style={{ marginTop: 16 }}
          rows={4}
        />
      </>
    )}
  </Modal>
);

const ViewDetailsModal = ({
  visible,
  order,
  onClose,
}: {
  visible: boolean;
  order: ReturnOrder | null;
  onClose: () => void;
}) => (
  <Modal
    title={<ModalTitle>Return Order Details #{order?.id}</ModalTitle>}
    visible={visible}
    onCancel={onClose}
    footer={[
      <Button key="close" onClick={onClose}>
        Close
      </Button>,
    ]}
    width={800}
  >
    {order && (
      <DetailsContainer>
        <Section>
          <SectionTitle>Store Information</SectionTitle>
          <InfoGrid>
            <InfoItem>
              <Space>
                {order.storeInfo.logo && (
                  <img
                    src={order.storeInfo.logo}
                    alt={order.storeInfo.name}
                    style={{
                      width: 60,
                      height: 60,
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                )}
                <Value style={{ fontSize: "16px", fontWeight: 500 }}>
                  {order.storeInfo.name}
                </Value>
              </Space>
            </InfoItem>
          </InfoGrid>
        </Section>

        <Section>
          <SectionTitle>Products</SectionTitle>
          <ProductsList>
            {order.orderItemInfos.map((item, index) => (
              <ProductCard key={index}>
                <ProductImage src={item.images[0]} />
                <ProductDetails>
                  <ProductName>{item.name}</ProductName>
                  <QuantityBadge>Quantity: x{item.quantity}</QuantityBadge>
                </ProductDetails>
              </ProductCard>
            ))}
          </ProductsList>
        </Section>

        <Section>
          <SectionTitle>Return Details</SectionTitle>
          <InfoGrid>
            <InfoItem>
              <Label>Total Return Amount</Label>
              <Value>{formatMoney(order.totalMoneyReturn)}</Value>
            </InfoItem>
            <InfoItem>
              <Label>Status</Label>
              <StatusTag $status={order.status}>
                {returnOrderStatusEnum[order.status]?.text}
              </StatusTag>
            </InfoItem>
            {order.returnShipment && (
              <>
                <InfoItem>
                  <Label>Carrier</Label>
                  <Value>
                    <Space>
                      {order.returnShipment.shipCarrierImage && (
                        <img
                          src={order.returnShipment.shipCarrierImage}
                          alt={order.returnShipment.shipCarrier}
                          style={{ height: 20, width: "auto" }}
                        />
                      )}
                      {order.returnShipment.shipCarrier} -{" "}
                      {order.returnShipment.shipServiceName}
                    </Space>
                  </Value>
                </InfoItem>
                <InfoItem>
                  <Label>Receiver</Label>
                  <Value>
                    {order.returnShipment.receiveName} (
                    {order.returnShipment.receivePhone})
                    <div style={{ color: "#666", fontSize: "13px" }}>
                      {order.returnShipment.receiveAddress}
                    </div>
                  </Value>
                </InfoItem>
                <InfoItem>
                  <Label>Delivery From</Label>
                  <Value>
                    {order.returnShipment.deliveryName} (
                    {order.returnShipment.deliveryPhone})
                    <div style={{ color: "#666", fontSize: "13px" }}>
                      {order.returnShipment.deliveryAddress}
                    </div>
                  </Value>
                </InfoItem>
              </>
            )}
          </InfoGrid>
        </Section>

        <Section>
          <SectionTitle>Reason</SectionTitle>
          <ReasonTitle>{order.title}</ReasonTitle>
          <ReasonDescription>{order.description}</ReasonDescription>
          {order.images && order.images.length > 0 && (
            <ImageGrid>
              {order.images.map((image, index) => (
                <Image
                  key={index}
                  src={image}
                  width={100}
                  height={100}
                  style={{ objectFit: "cover", borderRadius: "8px" }}
                />
              ))}
            </ImageGrid>
          )}
        </Section>

        {order.returnMessages && order.returnMessages.length > 0 && (
          <Section>
            <SectionTitle>Return Messages History</SectionTitle>
            <Timeline>
              {order.returnMessages.map((msg, index) => (
                <Timeline.Item key={index}>
                  <div style={{ fontWeight: 500 }}>
                    {new Date(msg.time).toLocaleString()}
                  </div>
                  <div style={{ color: "#666" }}>{msg.message}</div>
                </Timeline.Item>
              ))}
            </Timeline>
          </Section>
        )}

        {order.cancelRejectReason && (
          <Section>
            <SectionTitle>Rejection Reason</SectionTitle>
            <ReasonBox>{order.cancelRejectReason}</ReasonBox>
          </Section>
        )}
      </DetailsContainer>
    )}
  </Modal>
);

const ShipMsgModal = ({
  visible,
  messages,
  onClose,
}: {
  visible: boolean;
  messages: { time: number; message: string }[];
  onClose: () => void;
}) => (
  <Modal
    title="Shipping Status History"
    visible={visible}
    onCancel={onClose}
    footer={[
      <Button key="close" onClick={onClose}>
        Close
      </Button>,
    ]}
  >
    <Timeline>
      {messages.map((msg, index) => (
        <Timeline.Item key={index}>
          <div style={{ fontWeight: 500 }}>
            {new Date(msg.time).toLocaleString()}
          </div>
          <div style={{ color: "#666" }}>{msg.message}</div>
        </Timeline.Item>
      ))}
    </Timeline>
  </Modal>
);

export default function ReturnOrderTable({
  pageId,
  filters,
  buildQueryParams,
  updatePagination,
  onStatusCountsUpdate,
}: ReturnOrderTableProps) {
  console.log({ filters, buildQueryParams }, "filters");
  const [loading, setLoading] = useState(false);
  const [returns, setReturns] = useState<ReturnOrder[]>([]);
  const [total, setTotal] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [actionType, setActionType] = useState<"accept" | "reject" | null>(
    null
  );
  const [isReturningItems, setIsReturningItems] = useState(false);
  const [cancelRejectReason, setCancelRejectReason] = useState("");
  const [viewDetailsVisible, setViewDetailsVisible] = useState(false);
  const [selectedViewOrder, setSelectedViewOrder] =
    useState<ReturnOrder | null>(null);
  const [selectedShipMsg, setSelectedShipMsg] = useState<
    { time: number; message: string }[] | null
  >(null);

  const fetchReturns = useCallback(
    async (params: any) => {
      try {
        setLoading(true);
        const pageInfo = await helper.getPage(pageId);
        const response: any = await helper.callPageApi(
          pageInfo,
          "get-return-orders",
          params
        );
        if (response?.data) {
          setReturns(
            Array.isArray(response.data.data) ? response.data.data : []
          );
          setTotal(
            typeof response.data.count === "number" ? response.data.count : 0
          );

          if (response.data.statusCounts && onStatusCountsUpdate) {
            onStatusCountsUpdate(response.data.statusCounts);
          }
        }
      } catch (error) {
        console.error("Error fetching returns:", error);
        message.error("Failed to fetch return orders");
      } finally {
        setLoading(false);
      }
      // }
    },
    [pageId, onStatusCountsUpdate]
  );

  useEffect(() => {
    const params = buildQueryParams();
    fetchReturns(params);
    console.log({ params }, "params");
  }, [buildQueryParams, filters]);

  const handleTableChange = (pagination: any) => {
    updatePagination(pagination.current, pagination.pageSize);
    // Manually trigger a fetch when pagination changes
    const params = buildQueryParams();
    fetchReturns(params);
  };

  const handleAcceptClick = (record: any) => {
    setSelectedOrder(record);
    setActionType("accept");
    setConfirmModalVisible(true);
  };

  const handleRejectClick = (record: any) => {
    setSelectedOrder(record);
    setActionType("reject");
    setConfirmModalVisible(true);
  };

  const handleConfirmAction = async () => {
    try {
      setLoading(true);
      const pageInfo = await helper.getPage(pageId);

      const params = {
        orderReturnId: selectedOrder.id,
        status: actionType === "accept" ? "APPROVED" : "REJECTED",
        isReturningItems:
          actionType === "accept" ? isReturningItems : undefined,
        cancelRejectReason:
          actionType === "reject" ? cancelRejectReason : undefined,
      };

      await helper.callPageApi(pageInfo, "confirm-return-order", params);

      message.success(`Successfully ${actionType}ed return order`);
      setConfirmModalVisible(false);
      fetchReturns(buildQueryParams());
    } catch (error) {
      console.error(`Error ${actionType}ing return:`, error);
      message.error(`Failed to ${actionType} return order`);
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (record: ReturnOrder) => {
    setSelectedViewOrder(record);
    setViewDetailsVisible(true);
  };

  const handleConfirmDone = async (orderId: number) => {
    Modal.confirm({
      title: "Confirm Refund",
      content: "Are you sure you want to confirm this refund?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        try {
          setLoading(true);
          const pageInfo = await helper.getPage(pageId);

          await helper.callPageApi(pageInfo, "confirm-done", {
            orderReturnId: orderId,
          });

          message.success("Successfully confirmed refund");
          fetchReturns(buildQueryParams());
        } catch (error) {
          console.error("Error confirming refund:", error);
          message.error("Failed to confirm refund");
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Products",
      key: "product",
      render: (_: unknown, record: any) => (
        <ProductInfo>
          <ProductImage src={record.orderItemInfos[0].images[0]} />
          <ProductDetails>
            <div>{record.orderItemInfos[0].name}</div>
            <div>Quantity: x{record.orderItemInfos[0].quantity}</div>
            {record.orderItemInfos.length > 1 && (
              <Button
                type="link"
                onClick={() => {
                  setSelectedProducts(record.orderItemInfos);
                  setIsModalVisible(true);
                }}
              >
                +{record.orderItemInfos.length - 1} more products
              </Button>
            )}
          </ProductDetails>
        </ProductInfo>
      ),
    },
    {
      title: "Amount",
      dataIndex: "totalMoneyReturn",
      key: "totalMoneyReturn",
      render: (amount: number) => formatMoney(amount),
    },
    {
      title: "Reason",
      key: "reason",
      render: (_: unknown, record: any) => (
        <div>
          <div style={{ fontWeight: 500 }}>{record?.title}</div>
          <div style={{ color: "#666", fontSize: "13px", marginTop: "4px" }}>
            {record?.description}
          </div>
          {record?.images && record?.images?.length > 0 && (
            <div style={{ marginTop: "8px", display: "flex", gap: "8px" }}>
              {record?.images?.map((image: string, index: number) => (
                <Image
                  key={index}
                  src={image}
                  width={60}
                  height={60}
                  style={{ objectFit: "cover", borderRadius: "4px" }}
                />
              ))}
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (_: unknown, record: any) => (
        <StatusTag $status={record.status}>
          {returnOrderStatusEnum[
            record.status as keyof typeof returnOrderStatusEnum
          ]?.text || record.status}
        </StatusTag>
      ),
    },
    {
      title: "Return Shipment",
      key: "returnShipment",
      render: (_: unknown, record: ReturnOrder) => {
        if (!record.returnShipment) return "-";

        return (
          <Space direction="vertical" size="small">
            <div>
              <strong>Carrier:</strong>{" "}
              <Space>
                {record.returnShipment.shipCarrierImage && (
                  <img
                    src={record.returnShipment.shipCarrierImage}
                    alt={record.returnShipment.shipCarrier}
                    style={{ height: 20, width: "auto" }}
                  />
                )}
                {record.returnShipment.shipCarrier} -{" "}
                {record.returnShipment.shipServiceName}
              </Space>
            </div>
            <div>
              <strong>Receiver:</strong> {record.returnShipment.receiveName} (
              {record.returnShipment.receivePhone})
              <div style={{ color: "#666", fontSize: "13px" }}>
                {record.returnShipment.receiveAddress}
              </div>
            </div>
            <div>
              <strong>Delivery From:</strong>{" "}
              {record.returnShipment.deliveryName} (
              {record.returnShipment.deliveryPhone})
              <div style={{ color: "#666", fontSize: "13px" }}>
                {record.returnShipment.deliveryAddress}
              </div>
            </div>
            {record.returnShipment.shipMsg &&
              record.returnShipment.shipMsg.length > 0 && (
                <div>
                  <Space
                    direction="vertical"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <div>
                      <strong>Latest Status:</strong>{" "}
                      <div style={{ color: "#666", fontSize: "13px" }}>
                        {new Date(
                          record.returnShipment.shipMsg[0].time
                        ).toLocaleString()}
                        : {record.returnShipment.shipMsg[0].message}
                      </div>
                    </div>
                    <Button
                      type="link"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedShipMsg(
                          record.returnShipment?.shipMsg || []
                        );
                      }}
                    >
                      View All History ({record.returnShipment.shipMsg.length})
                    </Button>
                  </Space>
                </div>
              )}
            <ShipMsgModal
              visible={!!selectedShipMsg}
              messages={selectedShipMsg || []}
              onClose={() => setSelectedShipMsg(null)}
            />
          </Space>
        );
      },
    },
    {
      title: "Store",
      key: "store",
      render: (_: unknown, record: ReturnOrder) => (
        <Space>
          {record.storeInfo.logo && (
            <img
              src={record.storeInfo.logo}
              alt={record.storeInfo.name}
              style={{
                width: 40,
                height: 40,
                objectFit: "cover",
                borderRadius: "4px",
              }}
            />
          )}
          <span>{record.storeInfo.name}</span>
        </Space>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: unknown, record: ReturnOrder) => (
        <Space>
          <Button type="link" onClick={() => handleViewDetails(record)}>
            View Details
          </Button>
          {record.status === "PENDING" && (
            <>
              <Button type="primary" onClick={() => handleAcceptClick(record)}>
                Accept
              </Button>
              <Button danger onClick={() => handleRejectClick(record)}>
                Reject
              </Button>
            </>
          )}
          {record.status === "WAITING_MONEY_RETURN" && (
            <Button type="primary" onClick={() => handleConfirmDone(record.id)}>
              Confirm Refund
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <TableContainer>
      <Table
        columns={columns}
        dataSource={returns}
        loading={loading}
        pagination={{
          total,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} returns`,
          responsive: true,
          position: ["bottomCenter"],
        }}
        onChange={handleTableChange}
        rowKey="id"
        scroll={{ x: "max-content" }}
      />

      <ProductListModal
        visible={isModalVisible}
        products={selectedProducts}
        onClose={() => setIsModalVisible(false)}
      />

      <ActionConfirmModal
        visible={confirmModalVisible}
        actionType={actionType}
        loading={loading}
        isReturningItems={isReturningItems}
        cancelRejectReason={cancelRejectReason}
        onClose={() => setConfirmModalVisible(false)}
        onConfirm={handleConfirmAction}
        onReturningItemsChange={setIsReturningItems}
        onReasonChange={setCancelRejectReason}
      />

      <ViewDetailsModal
        visible={viewDetailsVisible}
        order={selectedViewOrder}
        onClose={() => setViewDetailsVisible(false)}
      />
    </TableContainer>
  );
}
