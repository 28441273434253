import React, { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Select, DatePicker, Form, Button, message, Statistic, Table } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import axios from 'axios';
import { ShoppingCartOutlined, DollarOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Loading from '@src/components/Loading';
import { connect } from 'dva';
import Statistics from '../statistics';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface OrderData {
  date: string;
  orders: number;
  revenue: number;
  statusCounts: {
    pending: number;
    processing: number;
    wait_for_confirmation: number;
    wait_for_pickup: number;
    shipping: number;
    completed: number;
    canceled: number;
    refund: number;
    refunded: number;
  };
}

interface TopProduct {
  id: number;
  name: string;
  quantity: number;
  revenue: number;
}

interface OverallStats {
  totalOrders: number;
  totalRevenue: number;
  statusCounts: {
    pending: number;
    processing: number;
    wait_for_confirmation: number;
    wait_for_pickup: number;
    shipping: number;
    completed: number;
    canceled: number;
    refund: number;
    refunded: number;
  };
}

interface HomeProps {
  authUser: any;
}

const ShopDashboard: React.FC = () => {
  const [form] = Form.useForm();
  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const [topProducts, setTopProducts] = useState<TopProduct[]>([]);
  const [overallStats, setOverallStats] = useState<OverallStats | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async (values: any) => {
    setLoading(true);
    try {
      const { timeFrame, dateRange } = values;
      const params: any = {
        timeFrame,
      };

      if (timeFrame === 'custom' && dateRange) {
        params.startDate = moment(dateRange[0]).format('YYYY-MM-DD');
        params.endDate = moment(dateRange[1]).format('YYYY-MM-DD');
      }

      const response = await axios.get('/admin/report/report-ordership', {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data.code === 0) {
        setOrderData(response.data.data.orderData);
        setTopProducts(response.data.data.topProducts);
        setOverallStats(response.data.data.overallStats);
      } else {
        message.error('Không thể tải dữ liệu báo cáo');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Có lỗi xảy ra khi tải dữ liệu');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({ timeFrame: '7days' });
    fetchData({ timeFrame: '7days' });
  }, [form, fetchData]);

  const topProductColumns = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right' as const,
    },
    {
      title: 'Doanh thu',
      dataIndex: 'revenue',
      key: 'revenue',
      align: 'right' as const,
      render: (value: number) => `${value.toLocaleString('vi-VN')}đ`,
    },
  ];

  if (loading) return <Loading />;

  return (
    <div className="p-6">
      <Card
        title="Báo cáo đơn hàng"
        extra={
          <Form form={form} onFinish={fetchData} layout="inline">
            <Form.Item name="timeFrame" initialValue="7days">
              <Select style={{ width: 200 }}>
                <Option value="7days">7 ngày gần nhất</Option>
                <Option value="month">Tháng này</Option>
                <Option value="year">Năm nay</Option>
                <Option value="custom">Tùy chỉnh</Option>
              </Select>
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => 
              prevValues.timeFrame !== currentValues.timeFrame
            }>
              {({ getFieldValue }) => {
                return getFieldValue('timeFrame') === 'custom' ? (
                  <Form.Item name="dateRange">
                    <RangePicker />
                  </Form.Item>
                ) : null;
              }}
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Áp dụng
              </Button>
            </Form.Item>
          </Form>
        }
      >
        {overallStats && (
          <Row gutter={16} className="mb-6">
            <Col span={6}>
              <Card>
                <Statistic
                  title="Tổng đơn hàng"
                  value={overallStats.totalOrders}
                  prefix={<ShoppingCartOutlined style={{ color: '#1890ff' }} />}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Tổng doanh thu"
                  value={overallStats.totalRevenue}
                  prefix={<DollarOutlined style={{ color: '#52c41a' }} />}
                  suffix="đ"
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Đơn hoàn thành"
                  value={overallStats.statusCounts.completed}
                  prefix={<CheckCircleOutlined style={{ color: '#52c41a' }} />}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Đơn hủy"
                  value={overallStats.statusCounts.canceled}
                  prefix={<CloseCircleOutlined style={{ color: '#ff4d4f' }} />}
                />
              </Card>
            </Col>
          </Row>
        )}

        <Row gutter={16}>
          <Col span={16}>
            <Card title="Biểu đồ đơn hàng" className="mb-6">
              <ResponsiveContainer width="100%" height={400}>
                <BarChart data={orderData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis yAxisId="left" orientation="left" stroke="#1890ff" />
                  <YAxis yAxisId="right" orientation="right" stroke="#52c41a" />
                  <Tooltip />
                  <Legend />
                  <Bar
                    yAxisId="left"
                    dataKey="orders"
                    fill="#1890ff"
                    name="Số đơn hàng"
                  />
                  <Bar
                    yAxisId="right"
                    dataKey="revenue"
                    fill="#52c41a"
                    name="Doanh thu"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Top sản phẩm bán chạy">
              <Table
                columns={topProductColumns}
                dataSource={topProducts}
                pagination={false}
                size="small"
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const Home: React.FC<HomeProps> = ({ authUser }) => {
  if (authUser?.roleSpecial === "SHOP") {
    return <ShopDashboard />;
  }
  return <Statistics />;
};

const mapStateToProps = ({ auth }: any) => ({
  authUser: auth.authUser,
});

export default connect(mapStateToProps)(Home); 