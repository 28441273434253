import React, { useState } from 'react';
import { Tabs, Input, Button, Space, Select, DatePicker, Radio, Checkbox, Typography, Rate } from 'antd';
import styled from 'styled-components';
import { StarFilled } from '@ant-design/icons';
import type { RadioChangeEvent } from 'antd';
import { RatingCounts } from '../types';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Text } = Typography;

const TabsContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  border-bottom: 1px solid #f0f0f0;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    padding: 0 16px;
    
    .ant-tabs-tab {
      padding: 12px 0;
      margin: 0 16px 0 0;
      transition: all 0.3s;
      
      &:hover {
        color: #9f60fc;
      }
      
      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #9f60fc;
        font-weight: 500;
      }
    }

    .ant-tabs-ink-bar {
      background: #9f60fc;
    }
  }
`;

const FilterContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: flex-start;
  background: #fff;
  border-bottom: 1px solid #edf2f7;
`;

const SearchGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  flex: 1;
  min-width: 300px;

  .ant-select {
    min-width: 160px;
  }

  .ant-input-search {
    flex: 1;
    min-width: 200px;
    max-width: 320px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .ant-btn {
    height: 36px;
    border-radius: 8px;
    padding: 0 16px;
  }
`;

const RatingFilter = styled.div`
  padding: 16px;
  border-bottom: 1px solid #edf2f7;
`;

const RatingHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 16px;

  .rating-summary {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .average-rating {
    font-size: 24px;
    font-weight: 500;
    color: #fadb14;
  }

  .rating-text {
    color: #666;
  }
`;

const RatingOptions = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 12px;
  flex-wrap: wrap;
`;

const RatingOption = styled(Checkbox)`
  display: flex !important;
  align-items: center;
  margin: 0 !important;
  
  .ant-checkbox + span {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-right: 0;
  }

  .star-count {
    color: #666;
    margin-left: 8px;
  }

  .anticon-star {
    color: #fadb14;
    font-size: 16px;
  }
`;

interface ReviewFiltersProps {
  filters: any;
  updateFilter: (key: string, value: any) => void;
  resetFilters: () => void;
  statusCounts: {
    total: number;
    pending: number;
    replied: number;
  };
  ratingStatistics: {
    oneStar: number;
    twoStar: number;
    threeStar: number;
    fourStar: number;
    fiveStar: number;
    averageRating: number;
    totalRatings: number;
  };
}

const ReviewFilters: React.FC<ReviewFiltersProps> = ({
  filters,
  updateFilter,
  resetFilters,
  statusCounts,
  ratingStatistics,
}) => {
  const [searchType, setSearchType] = useState('product');
  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState<any>(null);

  const handleTabChange = (key: string) => {
    updateFilter('status', key === 'all' ? '' : key);
  };

  const handleSearchTypeChange = (value: string) => {
    setSearchType(value);
    setSearchValue('');
    if (value !== 'date') {
      updateFilter('dateRange', undefined);
    }
  };

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleDateRangeChange = (dates: any) => {
    setDateRange(dates);
    if (dates) {
      updateFilter('dateRange', [
        dates[0].startOf('day').valueOf(),
        dates[1].endOf('day').valueOf()
      ]);
    } else {
      updateFilter('dateRange', undefined);
    }
  };

  const handleRatingChange = (rating: number) => {
    updateFilter('rating', filters.rating === rating ? undefined : rating);
  };

  const handleApplySearch = () => {
    const queryInput: Record<string, any> = {};

    if (searchValue && searchType !== 'date') {
      switch (searchType) {
        case 'product':
          queryInput.productName = searchValue;
          break;
        case 'buyer':
          queryInput.name = searchValue;
          break;
        case 'orderId':
          queryInput.orderId = searchValue;
          break;
      }
      updateFilter('queryInput', queryInput);
    }
  };

  const handleReset = () => {
    resetFilters();
    setSearchValue('');
    setDateRange(null);
    setSearchType('product');
  };

  const renderStars = (count: number) => {
    return Array(count).fill(null).map((_, index) => (
      <StarFilled key={index} />
    ));
  };

  return (
    <TabsContainer>
      <StyledTabs activeKey={filters.status || 'all'} onChange={handleTabChange}>
        <TabPane tab={`All (${statusCounts.total})`} key="all" />
        <TabPane tab={`Pending (${statusCounts.pending})`} key="pending" />
        <TabPane tab={`Replied (${statusCounts.replied})`} key="replied" />
      </StyledTabs>

      <RatingFilter>
        <RatingHeader>
          <Text strong>Rating Stars</Text>
          <div className="rating-summary">
            <span className="average-rating">{ratingStatistics.averageRating.toFixed(1)}</span>
            <Rate disabled value={Math.round(ratingStatistics.averageRating)} />
            <span className="rating-text">Average Rating</span>
          </div>
        </RatingHeader>
        <RatingOptions>
          <RatingOption 
            checked={!filters.rating}
            onChange={() => updateFilter('rating', undefined)}
          >
            All
            <span className="star-count">({ratingStatistics.totalRatings})</span>
          </RatingOption>
          <RatingOption
            checked={filters.rating === 5}
            onChange={() => handleRatingChange(5)}
          >
            {renderStars(5)}
            <span className="star-count">({ratingStatistics.fiveStar})</span>
          </RatingOption>
          <RatingOption
            checked={filters.rating === 4}
            onChange={() => handleRatingChange(4)}
          >
            {renderStars(4)}
            <span className="star-count">({ratingStatistics.fourStar})</span>
          </RatingOption>
          <RatingOption
            checked={filters.rating === 3}
            onChange={() => handleRatingChange(3)}
          >
            {renderStars(3)}
            <span className="star-count">({ratingStatistics.threeStar})</span>
          </RatingOption>
          <RatingOption
            checked={filters.rating === 2}
            onChange={() => handleRatingChange(2)}
          >
            {renderStars(2)}
            <span className="star-count">({ratingStatistics.twoStar})</span>
          </RatingOption>
          <RatingOption
            checked={filters.rating === 1}
            onChange={() => handleRatingChange(1)}
          >
            {renderStars(1)}
            <span className="star-count">({ratingStatistics.oneStar})</span>
          </RatingOption>
        </RatingOptions>
      </RatingFilter>

      <FilterContainer>
        <SearchGroup>
          <Select
            value={searchType}
            onChange={handleSearchTypeChange}
            style={{ width: 140 }}
          >
            <Select.Option value="product">Product Name</Select.Option>
            <Select.Option value="buyer">Buyer Name</Select.Option>
            <Select.Option value="orderId">Order ID</Select.Option>
            <Select.Option value="date">Review Date</Select.Option>
          </Select>

          {searchType === 'date' ? (
            <RangePicker
              value={dateRange}
              onChange={handleDateRangeChange}
              style={{ width: 280 }}
              placeholder={['From date', 'To date']}
              format="DD/MM/YYYY"
            />
          ) : (
            <Input.Search
              placeholder="Enter search keyword"
              value={searchValue}
              onChange={handleSearchValueChange}
              onSearch={handleApplySearch}
            />
          )}
        </SearchGroup>

        <ActionButtons>
          <Button
            type="primary"
            style={{ backgroundColor: '#9f60fc', borderColor: '#9f60fc' }}
            onClick={handleApplySearch}
          >
            Search
          </Button>
          <Button onClick={handleReset}>
            Reset
          </Button>
        </ActionButtons>
      </FilterContainer>
    </TabsContainer>
  );
};

export default ReviewFilters; 