import React from "react";
import { Button, /* Checkbox, */ Form, Input, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { connect } from "dva";
// import { router } from "dva";

import IntlMessages from "@src/util/IntlMessages";
import CircularProgress from "@src/components/CircularProgress/index";
import { StoreState } from "@src/interfaces";
// import * as authServices from '@src/services/auth';
import logoBackground from "../assets/images/logomm4.png";
// import logo from '../assets/images/vdslogo.png';
// import { IS_DEBUG } from '@src/constants/constants';
const FormItem = Form.Item;
const SITE_KEY = "6LfcZx4fAAAAACKpO5LW4YWI1LUfce6uutgqveDj";

class SignIn extends React.Component<
  {
    dispatch?: any;
    showMessage?: any;
    history?: any;
    authUser?: any;
    loader?: any;
    alertMessage?: any;
  },
  {
    tokenCapcha?: string;
    captcha: string;
    accountKitToken?: string;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      captcha: "",
      accountKitToken: "",
    };
  }

  form = React.createRef<any>();

  handleSubmit = (values: Record<string, any>) => {
    const { dispatch } = this.props;
    dispatch({
      type: "auth/showAuthLoader",
    });
    // dispatch({
    //   type: 'auth/userSignIn',
    //   payload: {
    //     ...values,
    //     captchaId: this.state.captchaId,
    //     tokenCapcha: this.state.tokenCapcha,
    //   },
    // });
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token: any) => {
          console.log("@@token", token);
          dispatch({
            type: "auth/userSignIn",
            payload: {
              ...values,
              tokenCapcha: token,
            },
          });
        });
    });
    console.log("!!@@ready", window);
  };

  // async loadCaptcha() {
  //   //load captcha
  //   const captInfo = await authServices.getCaptcha();
  //   if (IS_DEBUG) {
  //     console.log(
  //       `🚀 ~ file: SignIn.tsx ~ line 57 ~ loadCaptcha ~ captInfo`,
  //       captInfo
  //     );
  //   }

  //   this.setState({
  //     captchaId: captInfo.data.id,
  //     captcha: captInfo.data.data,
  //     tokenCapcha: captInfo.data.tokenCapcha,
  //   });
  // }

  loadScriptByURL = (id: any, url: any, callback: any) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  componentDidMount() {
    this.loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        console.log("Script loaded!");
      }
    );
  }

  componentDidUpdate() {
    const { dispatch } = this.props;
    if (this.props.showMessage) {
      setTimeout(() => {
        dispatch({
          type: "auth/hideMessage",
        });
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }

  render() {
    const { showMessage, loader, alertMessage } = this.props;

    return (
      <div className="gx-app-login-wrap" style={{
        background: 'linear-gradient(135deg, #f5f7fa 0%, #e4e8eb 100%)',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        padding: '40px 20px'
      }}>
        <div
          className="gx-app-login-container"
          style={{
            maxWidth: '1200px',
            width: '100%',
            background: '#ffffff',
            borderRadius: '30px',
            boxShadow: '0 20px 60px rgba(0, 0, 0, 0.08)',
            overflow: 'hidden',
            display: 'flex'
          }}
        >
          <div className="gx-app-logo-content" style={{ 
            width: '45%',
            background: 'linear-gradient(135deg, #4568dc 0%, #b06ab3 100%)',
            padding: '80px 60px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'relative'
          }}>
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'url("data:image/svg+xml,%3Csvg width=\'100\' height=\'100\' viewBox=\'0 0 100 100\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z\' fill=\'%23ffffff\' fill-opacity=\'0.1\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")',
              opacity: 0.3
            }} />
              {/* <div
                className="gx-app-logo-content-bg"
                style={{
                marginBottom: '40px',
                position: 'relative',
                zIndex: 1
                }}
              > */}
                <img
                  src={logoBackground}
                  alt="MediaOne"
                  style={{ 
                    width: '75%', 
                    height: 'auto',
                    marginBottom: '40px'
                  }}
                />
              {/* </div> */}
            <div style={{
              position: 'relative',
              zIndex: 1
            }}>
              <h2 style={{ 
                fontSize: '28px', 
                fontWeight: 600,
                color: '#fff',
                marginBottom: '24px'
              }}>Muamua Management System</h2>
              <p style={{ 
                fontSize: '16px', 
                lineHeight: 1.6,
                color: 'rgba(255,255,255,0.8)'
              }}>
                Professional management system for your business
              </p>
            </div>
          </div>

          <div className="gx-app-login-content" style={{
            width: '55%',
            padding: '80px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
            <div style={{ maxWidth: '440px', margin: '0 auto', width: '100%' }}>
              <h1 className="title-login" style={{
                fontSize: '36px',
                fontWeight: 700,
                color: '#1a1a1a',
                marginBottom: '16px'
              }}>
                Sign In
              </h1>
              <p style={{
                color: '#666',
                marginBottom: '48px',
                fontSize: '16px'
              }}>
                Please sign in to continue
              </p>

              <Form
                ref={this.form}
                onFinish={this.handleSubmit}
                className="gx-signin-form gx-form-row0"
              >
                <FormItem
                  name="username"
                  initialValue=""
                  rules={[
                    { required: true, message: "Please enter your username!" },
                  ]}
                  style={{ marginBottom: '24px' }}
                >
                  <Input
                    placeholder="Username"
                    prefix={<UserOutlined style={{ color: '#4568dc', fontSize: '18px' }} />}
                    style={{ 
                      height: '50px',
                      borderRadius: '12px',
                      border: '2px solid #e8e8e8',
                      transition: 'all 0.3s ease',
                      fontSize: '15px',
                      boxShadow: 'none',
                      ':hover': {
                        borderColor: '#4568dc'
                      },
                      ':focus': {
                        borderColor: '#4568dc',
                        boxShadow: '0 0 0 2px rgba(69,104,220,0.2)'
                      }
                    }}
                  />
                </FormItem>
                <FormItem
                  name="password"
                  initialValue=""
                  rules={[
                    { required: true, message: "Please enter your password!" },
                  ]}
                  style={{ marginBottom: '32px' }}
                >
                  <Input
                    type="password"
                    placeholder="Password"
                    prefix={<LockOutlined style={{ color: '#4568dc', fontSize: '18px' }} />}
                    style={{ 
                      height: '50px',
                      borderRadius: '12px',
                      border: '2px solid #e8e8e8',
                      transition: 'all 0.3s ease',
                      fontSize: '15px',
                      boxShadow: 'none',
                      ':hover': {
                        borderColor: '#4568dc'
                      },
                      ':focus': {
                        borderColor: '#4568dc',
                        boxShadow: '0 0 0 2px rgba(69,104,220,0.2)'
                      }
                    }}
                  />
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    className="gx-mb-0"
                    htmlType="submit"
                    style={{ 
                      width: '100%',
                      height: '50px',
                      borderRadius: '12px',
                      background: 'linear-gradient(135deg, #4568dc 0%, #b06ab3 100%)',
                      border: 'none',
                      fontSize: '16px',
                      fontWeight: 600,
                      marginTop: '24px',
                      boxShadow: '0 8px 25px rgba(69,104,220,0.25)',
                      transition: 'all 0.3s ease',
                      ':hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 12px 30px rgba(69,104,220,0.35)'
                      }
                    }}
                  >
                    Sign In
                  </Button>
                </FormItem>
              </Form>
            </div>
            </div>

            {loader ? (
            <div className="gx-loader-view" style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'rgba(255,255,255,0.9)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '30px',
              zIndex: 2
            }}>
              <CircularProgress className="loading-indicator" />
              </div>
            ) : null}
            {showMessage && alertMessage
              ? message.error(alertMessage.toString(), 10)
              : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }: StoreState) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps)(SignIn);
