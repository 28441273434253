import React, { useState } from 'react';
import { Card } from 'antd';
import styled from 'styled-components';
import ReviewFilters from './components/ReviewFilters';
import { useReviewFilters } from './hooks/useReviewFilters';
import ReviewTable from './components/ReviewTable';
import { helper } from '@src/controls/controlHelper';

const PAGE_ID = 321;

const StyledCard = styled(Card)`
  margin: 24px;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  .ant-card-body {
    padding: 0;
  }

  @media (max-width: 768px) {
    margin: 12px;
    border-radius: 12px;
  }
`;

export default function ReviewListCtrl() {
  const {
    filters,
    updateFilter,
    resetFilters,
    buildQueryParams,
    updatePagination,
  } = useReviewFilters();

  const [statusCounts, setStatusCounts] = useState({
    total: 0,
    pending: 0,
    replied: 0,
  });

  const [ratingStatistics, setRatingStatistics] = useState({
    oneStar: 0,
    twoStar: 0,
    threeStar: 0,
    fourStar: 0,
    fiveStar: 0,
    averageRating: 0,
    totalRatings: 0,
  });

  const [pageInfo, setPageInfo] = useState<any>();

  React.useEffect(() => {
    getPageInfo();
  }, []);

  const getPageInfo = async () => {
    const _pageInfo = await helper.getPage(PAGE_ID);
    setPageInfo(_pageInfo);
  };

  return (
    <StyledCard>
      <ReviewFilters 
        filters={filters}
        updateFilter={updateFilter}
        resetFilters={resetFilters}
        statusCounts={statusCounts}
        ratingStatistics={ratingStatistics}
      />
      <ReviewTable
        pageId={PAGE_ID}
        pageInfo={pageInfo}
        filters={filters}
        buildQueryParams={buildQueryParams}
        updatePagination={updatePagination}
        onStatusCountsUpdate={setStatusCounts}
        onRatingStatisticsUpdate={setRatingStatistics}
      />
    </StyledCard>
  );
} 