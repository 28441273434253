import { useState } from 'react';

interface Filters {
  status: string;
  queryInput?: Record<string, any>;
  dateRange?: [number, number];
  page: number;
  pageSize: number;
}

export const useReviewFilters = () => {
  const [filters, setFilters] = useState<Filters>({
    status: '',
    page: 1,
    pageSize: 10,
  });

  const updateFilter = (key: string, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const resetFilters = () => {
    setFilters({
      status: '',
      page: 1,
      pageSize: 10,
    });
  };

  const updatePagination = (page: number, pageSize: number) => {
    setFilters((prev) => ({
      ...prev,
      page,
      pageSize,
    }));
  };

  const buildQueryParams = () => {
    const { status, queryInput, dateRange, page, pageSize } = filters;
    
    const params: Record<string, any> = {
      page,
      pageSize,
    };

    if (status) {
      params.status = status;
    }

    if (queryInput) {
      Object.assign(params, queryInput);
    }

    if (dateRange) {
      params.createdAt = {
        gte: dateRange[0],
        lte: dateRange[1],
      };
    }

    return params;
  };

  return {
    filters,
    updateFilter,
    resetFilters,
    updatePagination,
    buildQueryParams,
  };
}; 