import { useCallback, useState } from 'react';
import { message } from 'antd';
import axios from 'axios';

const useGoogleTranslate = () => {
  const [isLoading, setIsLoading] = useState(false);

  const translateElement = async (element: Element, targetLang: string) => {
    // Skip elements with no direct text content
    const hasDirectText = Array.from(element.childNodes).some(
      node => node.nodeType === Node.TEXT_NODE && node.textContent?.trim()
    );
    if (!hasDirectText) return;

    // Get only the direct text content, excluding child elements
    const textContent = Array.from(element.childNodes)
      .filter(node => node.nodeType === Node.TEXT_NODE)
      .map(node => node.textContent)
      .join('')
      .trim();

    if (!textContent) return;

    try {
      // Skip if text is already in target language
      if (element.getAttribute('data-translated') === targetLang) return;

      // Skip if text contains only special characters or numbers
      if (/^[^a-zA-Z]*$/.test(textContent)) return;

      // Use Google Translate API
      const response = await axios.get(
        `https://translate.googleapis.com/translate_a/single?client=gtx&sl=auto&tl=${targetLang}&dt=t&q=${encodeURIComponent(textContent)}`
      );

      if (response.data && response.data[0] && response.data[0][0]) {
        const translatedText = response.data[0][0][0];
        
        // Store original text as data attribute
        if (!element.getAttribute('data-original')) {
          element.setAttribute('data-original', textContent);
        }
        
        // Only update text nodes, preserve all other child elements
        element.childNodes.forEach(node => {
          if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim()) {
            node.textContent = translatedText;
          }
        });
        
        element.setAttribute('data-translated', targetLang);
      }
    } catch (error) {
      console.error('Error translating element:', error);
    }
  };

  const translatePage = useCallback(async (targetLang: string) => {
    try {
      setIsLoading(true);
      message.loading('Đang dịch...', 0);

      // Reset translation if switching back to English
      if (targetLang === 'en') {
        const translatedElements = document.querySelectorAll('[data-translated]');
        translatedElements.forEach(element => {
          const originalText = element.getAttribute('data-original');
          if (originalText) {
            element.childNodes.forEach(node => {
              if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim()) {
                node.textContent = originalText;
              }
            });
          }
          element.removeAttribute('data-translated');
          element.removeAttribute('data-original');
        });
        
        setIsLoading(false);
        message.destroy();
        message.success('Switched to English');
        return;
      }

      // Get all text elements but exclude icons and special components
      const menuElements = document.querySelectorAll(`
        .ant-menu-title-content,
        .ant-menu-submenu-title > span:not(.ant-menu-submenu-arrow),
        .ant-menu-item > a,
        .ant-menu-item > span
      `);

      const otherElements = document.querySelectorAll(`
        h1, h2, h3, h4, h5, h6,
        p,
        span:not(.anticon):not(.ant-menu-submenu-arrow):not([class*="icon"]),
        div:not(.ant-message):not(.anticon):not([class*="icon"]),
        a:not(.anticon):not([class*="icon"]),
        button:not(.anticon):not([class*="icon"]),
        label,
        input[type="text"],
        input[type="button"],
        input[type="submit"]
      `);
      
      const elements = [...Array.from(menuElements), ...Array.from(otherElements)];
      
      const promises = elements
        .filter(element => {
          // Skip if element has icon class or is a parent of icon
          const hasIcon = 
            element.classList.contains('anticon') || 
            element.classList.contains('icon') ||
            element.querySelector('.anticon') || 
            element.querySelector('.icon') ||
            element.closest('[class*="icon"]');
            
          // Skip elements with click handlers if they don't have text
          const hasClickHandler = 
            element.hasAttribute('onclick') || 
            element.hasAttribute('onClick') ||
            element.tagName.toLowerCase() === 'button' ||
            element.tagName.toLowerCase() === 'a';

          // Skip if element is inside an icon
          const isInsideIcon = 
            element.closest('.anticon') || 
            element.closest('.icon');

          // Get actual text content
          const textContent = Array.from(element.childNodes)
            .filter(node => node.nodeType === Node.TEXT_NODE)
            .map(node => node.textContent)
            .join('')
            .trim();
            
          return !hasIcon && !isInsideIcon && textContent && (!hasClickHandler || textContent);
        })
        .map(element => translateElement(element, targetLang));

      await Promise.all(promises);

      setIsLoading(false);
      message.destroy();
      message.success('Đã dịch xong!');
    } catch (error) {
      console.error('Translation error:', error);
      setIsLoading(false);
      message.destroy();
      message.error('Không thể dịch trang. Vui lòng thử lại sau.');
    }
  }, []);

  return { translatePage, isLoading };
};

export default useGoogleTranslate; 