import { returnOrderStatusEnum } from "@src/util/enums";
import { Tag, Image } from "antd";
import styled from "styled-components";

export const TableContainer = styled.div`
  padding: 24px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);

  .ant-table-wrapper {
    .ant-table {
      background: transparent;

      .ant-table-thead > tr > th {
        background: #f8fafc;
        font-weight: 600;
        padding: 16px 12px;
        border-bottom: 1px solid #edf2f7;
      }
    }
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
  padding: 12px 0;
  width: 100%;
  max-width: 400px;
`;

export const ProductImage = styled(Image)`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  flex-shrink: 0;
`;

export const ProductDetails = styled.div`
  flex: 1;
  min-width: 0;
`;

export const StatusTag = styled(Tag)<{ $status: string }>`
  min-width: 120px;
  text-align: center;
  padding: 6px 16px;
  height: 32px;
  line-height: 20px;
  border-radius: 16px;
  font-weight: 500;
  font-size: 13px;
  color: #fff;
  background-color: ${({ $status }) =>
    returnOrderStatusEnum[$status as keyof typeof returnOrderStatusEnum]
      ?.color || "#999"};
  border: none;
`;

export const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 4px;
`;

export const Section = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 16px;
`;

export const SectionTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
`;

export const ModalTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
`;

export const ProductsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ProductCard = styled(ProductInfo)`
  background: #f9fafb;
  padding: 12px;
  border-radius: 8px;
  margin: 0;
`;

export const ProductName = styled.div`
  font-weight: 500;
  color: #1f2937;
`;

export const QuantityBadge = styled.div`
  font-size: 13px;
  color: #6b7280;
  margin-top: 4px;
`;

export const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Label = styled.span`
  font-size: 13px;
  color: #6b7280;
`;

export const Value = styled.span`
  font-size: 15px;
  font-weight: 500;
  color: #1f2937;
`;

export const ReasonTitle = styled.h5`
  font-size: 15px;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 8px;
`;

export const ReasonDescription = styled.p`
  color: #4b5563;
  margin-bottom: 16px;
`;

export const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 8px;
  margin-top: 12px;
`;

export const ReasonBox = styled.div`
  background: #f9fafb;
  padding: 12px 16px;
  border-radius: 8px;
  color: #4b5563;
`;
