class NotificationService {
  private static instance: NotificationService;
  private audio: HTMLAudioElement | null = null;

  private constructor() {
    this.audio = new Audio('/notification.mp3');
    this.audio.volume = 0.5;
  }

  public static getInstance(): NotificationService {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService();
    }
    return NotificationService.instance;
  }

  public playSound() {
    if (this.audio) {
      this.audio.currentTime = 0;
      this.audio.play().catch(err => console.log('Error playing sound:', err));
    }
  }

  public setVolume(volume: number) {
    if (this.audio) {
      this.audio.volume = Math.min(1, Math.max(0, volume));
    }
  }

  public cleanup() {
    if (this.audio) {
      this.audio = null;
    }
  }
}

export default NotificationService; 