import React, { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Statistic, message } from 'antd';
import axios from 'axios';
import { ShoppingCartOutlined, DollarOutlined, CheckCircleOutlined, CloseCircleOutlined, UserOutlined, AppstoreOutlined } from '@ant-design/icons';
import Loading from '@src/components/Loading';
import styled from 'styled-components';

interface DashboardStats {
  totalSellers: number;
  newSellers: number;
  totalProducts: number;
  newProducts: number;
  totalOrders: {
    total: number;
    completed: number;
    canceled: number;
  };
  totalTransactionValue: number;
}

// Styled Components
const StyledCard = styled(Card)`
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  }
`;

const HeaderSection = styled.div`
  text-align: center;
  margin-bottom: 2.5rem;
  background: linear-gradient(135deg, #f0f5ff 0%, #ffffff 100%);
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
`;

const HeaderTitle = styled.h1`
  font-size: 28px;
  font-weight: 800;
  color: #1890ff;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: linear-gradient(45deg, #1890ff, #69c0ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StatisticWrapper = styled.div`
  padding: 1.5rem;
  height: 100%;
  border-radius: 12px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, ${props => props.gradientStart}, ${props => props.gradientEnd});
  }
`;

const NewItemsBox = styled.div`
  margin-top: 1rem;
  padding: 0.8rem;
  background: ${props => props.background};
  border-radius: 8px;
  font-size: 14px;
  border: 1px solid ${props => props.borderColor};
`;

const Statistics: React.FC = () => {
  const [dashboardStats, setDashboardStats] = useState<DashboardStats | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('/admin/report/dashboard-statistics', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.data) {
        setDashboardStats(response.data);
      } else {
        message.error('Không thể tải dữ liệu thống kê');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Có lỗi xảy ra khi tải dữ liệu');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) return <Loading />;

  return (
    <div className="p-8">
      <StyledCard>
        <HeaderSection>
          <HeaderTitle>Báo Cáo Số Liệu</HeaderTitle>
          <div style={{ fontSize: '17px', color: '#666', fontWeight: 500 }}>
            Từ ngày 01/01/202x – đến thời điểm truy cập
          </div>
          <div style={{ fontSize: '15px', color: '#888', fontStyle: 'italic', marginTop: '0.5rem' }}>
            (Ví dụ năm nay là năm 2025 {'>>'} Thời điểm thống kê sẽ tính từ 01/01/2025)
          </div>
        </HeaderSection>
        
        {dashboardStats && (
          <>
            <Row gutter={[24, 24]} className="mb-8">
              <Col span={12}>
                <StyledCard bodyStyle={{ padding: 0 }}>
                  <StatisticWrapper gradientStart="#1890ff" gradientEnd="#69c0ff">
                    <Statistic
                      title={<div style={{ fontSize: '18px', color: '#1890ff', fontWeight: 600, marginBottom: '1rem' }}>Tổng Số Người Bán</div>}
                      value={dashboardStats.totalSellers}
                      prefix={<UserOutlined style={{ color: '#1890ff', fontSize: '28px' }} />}
                      valueStyle={{ color: '#1890ff', fontSize: '32px', fontWeight: 700 }}
                    />
                    <NewItemsBox background="#f0f5ff" borderColor="#bae0ff">
                      <span style={{ color: '#666' }}>Số lượng người bán mới: </span>
                      <span style={{ color: '#1890ff', fontWeight: 600 }}>{dashboardStats.newSellers}</span>
                    </NewItemsBox>
                  </StatisticWrapper>
                </StyledCard>
              </Col>
              <Col span={12}>
                <StyledCard bodyStyle={{ padding: 0 }}>
                  <StatisticWrapper gradientStart="#52c41a" gradientEnd="#95de64">
                    <Statistic
                      title={<div style={{ fontSize: '18px', color: '#52c41a', fontWeight: 600, marginBottom: '1rem' }}>Tổng Số Sản Phẩm</div>}
                      value={dashboardStats.totalProducts}
                      prefix={<AppstoreOutlined style={{ color: '#52c41a', fontSize: '28px' }} />}
                      valueStyle={{ color: '#52c41a', fontSize: '32px', fontWeight: 700 }}
                    />
                    <NewItemsBox background="#f6ffed" borderColor="#b7eb8f">
                      <span style={{ color: '#666' }}>Số sản phẩm đăng bán mới: </span>
                      <span style={{ color: '#52c41a', fontWeight: 600 }}>{dashboardStats.newProducts}</span>
                    </NewItemsBox>
                  </StatisticWrapper>
                </StyledCard>
              </Col>
            </Row>

            <StyledCard 
              title={<div style={{ fontSize: '20px', color: '#1890ff', fontWeight: 600 ,marginLeft: '1rem'}}>Thống Kê Đơn Hàng</div>}
              className="mb-8"
              bodyStyle={{ background: '#fafafa', borderRadius: '8px' }}
            >
              <Row gutter={[24, 24]}>
                <Col span={8}>
                  <StyledCard bordered={false}>
                    <Statistic
                      title={<div style={{ color: '#666', fontSize: '16px', fontWeight: 500 }}>Tổng Đơn Hàng</div>}
                      value={dashboardStats.totalOrders.total}
                      prefix={<ShoppingCartOutlined style={{ color: '#1890ff', fontSize: '28px' }} />}
                      valueStyle={{ color: '#1890ff', fontSize: '28px', fontWeight: 700 }}
                    />
                  </StyledCard>
                </Col>
                <Col span={8}>
                  <StyledCard bordered={false}>
                    <Statistic
                      title={<div style={{ color: '#666', fontSize: '16px', fontWeight: 500 }}>Đơn Hàng Thành Công</div>}
                      value={dashboardStats.totalOrders.completed}
                      valueStyle={{ color: '#3f8600', fontSize: '28px', fontWeight: 700 }}
                      prefix={<CheckCircleOutlined style={{ color: '#3f8600', fontSize: '28px' }} />}
                    />
                  </StyledCard>
                </Col>
                <Col span={8}>
                  <StyledCard bordered={false}>
                    <Statistic
                      title={<div style={{ color: '#666', fontSize: '16px', fontWeight: 500 }}>Đơn Hàng Thất Bại</div>}
                      value={dashboardStats.totalOrders.canceled}
                      valueStyle={{ color: '#cf1322', fontSize: '28px', fontWeight: 700 }}
                      prefix={<CloseCircleOutlined style={{ color: '#cf1322', fontSize: '28px' }} />}
                    />
                  </StyledCard>
                </Col>
              </Row>
            </StyledCard>

            <StyledCard bodyStyle={{ padding: 0 }}>
              <StatisticWrapper gradientStart="#faad14" gradientEnd="#ffd666">
                <Statistic
                  title={<div style={{ fontSize: '18px', color: '#faad14', fontWeight: 600, marginBottom: '1rem' }}>Tổng Giá Trị Giao Dịch</div>}
                  value={dashboardStats.totalTransactionValue}
                  prefix={<DollarOutlined style={{ color: '#faad14', fontSize: '28px' }} />}
                  suffix="đ"
                  precision={0}
                  valueStyle={{ color: '#faad14', fontSize: '32px', fontWeight: 700 }}
                />
              </StatisticWrapper>
            </StyledCard>
          </>
        )}
      </StyledCard>
    </div>
  );
};

export default Statistics; 