import { useState, useCallback } from 'react';

interface ReturnOrderFilters {
  status: string;
  searchType: string;
  searchValue: string;
  queryInput?: Record<string, any>;
  dateRange?: [number, number];
  pagination: {
    current: number;
    pageSize: number;
  };
}

export function useReturnOrderFilters() {
  const [filters, setFilters] = useState<ReturnOrderFilters>({
    status: 'all',
    searchType: 'orderId',
    searchValue: '',
    queryInput: {},
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const buildQueryParams = useCallback(() => {
    const queryParams: Record<string, any> = {
      skip: (filters.pagination.current - 1) * filters.pagination.pageSize,
      limit: filters.pagination.pageSize,
    };

    if (filters.queryInput && Object.keys(filters.queryInput).length > 0) {
      queryParams.queryInput = JSON.stringify(filters.queryInput);
    }

    // Handle status filter
    if (filters.status && filters.status !== 'all') {
      if (!queryParams.queryInput) {
        queryParams.queryInput = JSON.stringify({});
      }
      
      const queryInputObj = typeof queryParams.queryInput === 'string' 
        ? JSON.parse(queryParams.queryInput) 
        : queryParams.queryInput;

      queryInputObj.status = filters.status;
      queryParams.queryInput = JSON.stringify(queryInputObj);
    }

    // Handle search filters
    if (filters.searchValue && filters.searchType !== 'date') {
      if (!queryParams.queryInput) {
        queryParams.queryInput = JSON.stringify({});
      }

      const queryInputObj = typeof queryParams.queryInput === 'string' 
        ? JSON.parse(queryParams.queryInput) 
        : queryParams.queryInput;

      switch (filters.searchType) {
        case 'orderId':
          queryInputObj.orderId = Number(filters.searchValue);
          break;
        case 'returnId':
          queryInputObj.id = Number(filters.searchValue);
          break;
      }

      queryParams.queryInput = JSON.stringify(queryInputObj);
    }

    // Handle date range
    if (filters.dateRange) {
      if (!queryParams.queryInput) {
        queryParams.queryInput = JSON.stringify({});
      }

      const queryInputObj = typeof queryParams.queryInput === 'string' 
        ? JSON.parse(queryParams.queryInput) 
        : queryParams.queryInput;

      queryInputObj.createdAt = {
        gte: filters.dateRange[0],
        lte: filters.dateRange[1]
      };

      queryParams.queryInput = JSON.stringify(queryInputObj);
    }

    return queryParams;
  }, [filters]);

  const updateFilter = useCallback((key: keyof ReturnOrderFilters, value: any) => {
    setFilters((prev) => {
      const newFilters = {
        ...prev,
        [key]: value,
      };

      // Reset pagination to first page when filters change (except for pagination itself)
      if (key !== 'pagination') {
        newFilters.pagination = {
          ...prev.pagination,
          current: 1,
        };
      }

      return newFilters;
    });
  }, []);

  const updatePagination = useCallback((current: number, pageSize: number) => {
    updateFilter('pagination', { current, pageSize });
  }, []);

  const resetFilters = useCallback(() => {
    setFilters({
      status: 'all',
      searchType: 'orderId',
      searchValue: '',
      queryInput: {},
      dateRange: undefined,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
  }, []);

  return {
    filters,
    updateFilter,
    updatePagination,
    resetFilters,
    buildQueryParams,
  };
} 