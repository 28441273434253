import { Component, useState } from "react";
import { Layout } from "antd";
import { Button } from "antd";
import { MessageOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Socket from "@src/util/Socket";
import { SOCKET_EVENTS, MESSAGE_TYPE, SENDER_TYPE } from "@src/types/chat";
import local from "@src/util/local";
import NotificationService from "@src/services/notification";

import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";

import Topbar from "../Topbar/index";
import { footerText } from "@src/util/config";
import App from "@src/routes/index";

import { connect } from "dva";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { StoreState } from "@src/interfaces";
import Customizer from "../Customizer";
import ChatDialog from "@src/controls/layouts/schemaTemplate/order/components/chat/ChatDialog";
import ChatDrawer from "@src/controls/layouts/schemaTemplate/order/components/chat/ChatDrawer";

interface MainAppProps {
  match?: any;
  width?: any;
  navStyle?: any;
  authUser?: any;
}

interface MainAppState {
  chatDrawerVisible: boolean;
  chatDialogVisible: boolean;
  selectedUserInfo: any;
  selectedConversation: any;
  chatMessages: any[];
  socketInstance: any;
  unreadConversations: string[];
}

const { Content, Footer } = Layout;

const ChatButton = styled(Button)`
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #9f60fc;
  border: none;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;

  &:hover {
    background: #8c4fe3;
    transform: translateY(-2px);
  }

  .anticon {
    font-size: 24px;
    color: white;
  }
`;

export class MainApp extends Component<MainAppProps, MainAppState> {
  private audioRef: HTMLAudioElement | null = null;
  private notificationService: NotificationService;

  state: MainAppState = {
    chatDrawerVisible: false,
    chatDialogVisible: false,
    selectedUserInfo: undefined,
    selectedConversation: null,
    chatMessages: [],
    socketInstance: null,
    unreadConversations: []
  };

  constructor(props: MainAppProps) {
    super(props);
    this.notificationService = NotificationService.getInstance();
  }

  componentDidMount() {
    // Khởi tạo audio element
    this.audioRef = new Audio('/notification.mp3');
    this.audioRef.volume = 0.5;

    if (this.props.authUser?.roleSpecial === "SHOP") {
      this.initializeSocket();
    }
  }

  componentWillUnmount() {
    if (this.state.socketInstance) {
      this.state.socketInstance.disconnect();
    }
    if (this.audioRef) {
      this.audioRef = null;
    }
    this.notificationService.cleanup();
  }

  playNotificationSound = () => {
    if (this.audioRef) {
      this.audioRef.currentTime = 0;
      this.audioRef.play().catch(err => console.log('Error playing sound:', err));
    }
  };

  initializeSocket = () => {
    const token = localStorage.getItem('token_chat');
    if (!token) return;

    const socket = new Socket({
      token,
      receiveMessageCallback: this.handleNewMessage
    });

    const socketAdapter = socket.getSocketAdapter();
    
    // Lắng nghe sự kiện kết nối
    socketAdapter.on(SOCKET_EVENTS.CONNECT, () => {
      console.log('Socket connected');
    });

    // Lắng nghe sự kiện ngắt kết nối
    socketAdapter.on(SOCKET_EVENTS.DISCONNECT, () => {
      console.log('Socket disconnected');
    });

    // Lắng nghe sự kiện lỗi
    socketAdapter.on(SOCKET_EVENTS.ERROR, (error: any) => {
      console.error('Socket error:', error);
    });

    // Lắng nghe sự kiện tin nhắn mới cho conversation
    socketAdapter.on(SOCKET_EVENTS.NEW_CONVERSATION_MESSAGE, (data: any) => {
      const { message, conversation } = data;
      
      // Nếu tin nhắn không phải từ người bán
      if (message.senderType !== SENDER_TYPE.SELLER) {
        // Phát âm thanh thông báo
        this.notificationService.playSound();

        // Nếu chưa mở chat dialog hoặc đang chat với người khác
        if (
          !this.state.chatDialogVisible || 
          this.state.selectedConversation?._id !== conversation._id
        ) {
          // Cập nhật danh sách conversation chưa đọc
          this.setState(prevState => ({
            unreadConversations: [...prevState.unreadConversations, conversation._id]
          }));
          
          // Lưu vào localStorage
          localStorage.setItem(
            'unreadConversations',
            JSON.stringify([...this.state.unreadConversations, conversation._id])
          );

          // Nếu chưa mở chat dialog nào thì mở dialog với conversation mới
          if (!this.state.chatDialogVisible) {
            // Format user info đúng cấu trúc
            const formattedUserInfo = {
              id: conversation.userId,
              name: conversation.name || '',
              avatar: conversation.avatar || ''
            };

            const formattedConversation = {
              _id: conversation._id,
              userId: conversation.userId,
              sellerId: conversation.sellerId,
              lastMessage: message,
              updatedAt: conversation.updatedAt,
              userInfo: {
                id: conversation.userId,
                name: conversation.name || '',
                avatar: conversation.avatar || ''
              }
            };

            this.setState({
              chatDialogVisible: true,
              selectedConversation: formattedConversation,
              selectedUserInfo: formattedUserInfo,
              chatMessages: [{
                id: message._id || Date.now().toString(),
                content: message.content,
                isMine: false,
                timestamp: new Date(message.timestamp),
                type: message.messageType,
                attachments: message.attachments,
                senderId: message.senderId,
                senderType: message.senderType,
                recipientId: message.recipientId,
                orderInfo: message.orderInfo,
                productInfo: message.productInfo
              }]
            });
          }
        }

        // Nếu đang mở chat dialog với conversation này
        if (
          this.state.chatDialogVisible && 
          this.state.selectedConversation?._id === conversation._id
        ) {
          this.setState(prevState => ({
            chatMessages: [...prevState.chatMessages, {
              id: message._id || Date.now().toString(),
              content: message.content,
              isMine: false,
              timestamp: new Date(message.timestamp),
              type: message.messageType,
              attachments: message.attachments,
              senderId: message.senderId,
              senderType: message.senderType,
              recipientId: message.recipientId,
              orderInfo: message.orderInfo,
              productInfo: message.productInfo
            }]
          }));
        }
      }
    });

    this.setState({ socketInstance: socketAdapter });
  };

  handleNewMessage = (message: any) => {
    if (
      message.senderType !== SENDER_TYPE.SELLER && 
      this.state.selectedConversation?._id === message.conversationId
    ) {
      const newMessage = {
        id: message._id || Date.now().toString(),
        content: message.content,
        isMine: false,
        timestamp: new Date(message.timestamp),
        type: message.messageType,
        attachments: message.attachments,
        senderId: message.senderId,
        senderType: message.senderType,
        recipientId: message.recipientId,
        orderInfo: message.orderInfo,
        productInfo: message.productInfo
      };

      this.setState(prevState => ({
        chatMessages: [...prevState.chatMessages, newMessage]
      }));
    }
  };

  handleOpenChatDrawer = () => {
    this.setState({ chatDrawerVisible: !this.state.chatDrawerVisible });
  };

  handleCloseDrawer = () => {
    this.setState({ chatDrawerVisible: false });
  };

  handleCloseChat = () => {
    this.setState({
      chatDialogVisible: false,
      selectedUserInfo: undefined,
      selectedConversation: null,
    });
  };

  handleChatSelect = (
    conversationId: string,
    userInfo: any,
    conversation: any
  ) => {
    // Xóa conversation khỏi danh sách chưa đọc
    const updatedUnreadConversations = this.state.unreadConversations.filter(
      id => id !== conversationId
    );
    
    this.setState({
      selectedUserInfo: userInfo,
      selectedConversation: conversation,
      chatDialogVisible: true,
      unreadConversations: updatedUnreadConversations
    });

    // Cập nhật localStorage
    localStorage.setItem(
      'unreadConversations',
      JSON.stringify(updatedUnreadConversations)
    );
  };

  handleSendMessage = (content: string, type: MESSAGE_TYPE = MESSAGE_TYPE.TEXT, attachments?: string[]) => {
    if (this.state.socketInstance && this.state.selectedConversation?._id) {
      const userInfo = local.get('user_id');
      const senderId = userInfo?.id;

      if (senderId) {
        const messageData = {
          conversationId: this.state.selectedConversation._id,
          senderId: senderId,
          senderType: SENDER_TYPE.SELLER,
          recipientId: this.state.selectedConversation.userId,
          content: content,
          messageType: type,
          timestamp: Date.now(),
          attachments
        };

        this.state.socketInstance.emit(SOCKET_EVENTS.NEW_MESSAGE, messageData);

        const newMessage = {
          id: Date.now().toString(),
          content: content,
          isMine: true,
          timestamp: new Date(),
          type: type,
          attachments,
          senderId: senderId,
          senderType: SENDER_TYPE.SELLER,
          recipientId: this.state.selectedConversation.userId
        };

        this.setState(prevState => ({
          chatMessages: [...prevState.chatMessages, newMessage]
        }));
      }
    }
  };

  getContainerClass = (navStyle: string) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return "gx-container-wrap";
      default:
        return "";
    }
  };

  getNavStyles = (navStyle: string) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return <HorizontalDefault />;
      case NAV_STYLE_DARK_HORIZONTAL:
        return <HorizontalDark />;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return <InsideHeader />;
      case NAV_STYLE_ABOVE_HEADER:
        return <AboveHeader />;
      case NAV_STYLE_BELOW_HEADER:
        return <BelowHeader />;
      case NAV_STYLE_FIXED:
        return <Topbar />;
      case NAV_STYLE_DRAWER:
        return <Topbar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Topbar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <NoHeaderNotification />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <NoHeaderNotification />;
      default:
        return "";
    }
  };

  getSidebar = (navStyle: string, width: number) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED:
        return <Sidebar />;
      case NAV_STYLE_DRAWER:
        return <Sidebar />;
      case NAV_STYLE_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
        return <Sidebar />;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar />;
      default:
        return <Sidebar />;
    }
  };

  render() {
    const { match, width, navStyle, authUser } = this.props;
    const {
      chatDrawerVisible,
      chatDialogVisible,
      selectedUserInfo,
      selectedConversation,
      chatMessages,
    } = this.state;
    return (
      <Layout className="gx-app-layout">
        {this.getSidebar(navStyle, width)}
        <Layout>
          {this.getNavStyles(navStyle)}
          <Content
            className={`gx-layout-content ${this.getContainerClass(navStyle)} `}
          >
            <App match={match} />
            <Footer>
              <div className="gx-layout-footer-content">{footerText}</div>
            </Footer>
          </Content>
        </Layout>
        {authUser && authUser.role == 1 && <Customizer />}
        {authUser && authUser.roleSpecial === "SHOP" && (
          <>
            <ChatDrawer
              visible={chatDrawerVisible}
              onClose={this.handleCloseDrawer}
              onChatSelect={this.handleChatSelect}
            />

            <ChatDialog
              visible={chatDialogVisible}
              onClose={this.handleCloseChat}
              userInfo={selectedUserInfo}
              conversation={selectedConversation}
              messages={chatMessages}
              onSendMessage={this.handleSendMessage}
            />

            <ChatButton
              type="primary"
              icon={<MessageOutlined />}
              onClick={this.handleOpenChatDrawer}
              title="Chat"
            />
          </>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = ({ settings, auth }: StoreState) => {
  const { width, navStyle } = settings;
  const { authUser } = auth;
  return { width, navStyle, authUser };
};
export default connect(mapStateToProps)(MainApp);
