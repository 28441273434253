import React, { useState } from 'react';
import { Button, Dropdown, Menu } from 'antd';
import { GlobalOutlined, LoadingOutlined } from '@ant-design/icons';
import useGoogleTranslate from '../../hooks/useGoogleTranslate';

const LanguageSelector: React.FC = () => {
  const { translatePage, isLoading } = useGoogleTranslate();
  const [currentLang, setCurrentLang] = useState('en');

  const handleLanguageChange = async (lang: string) => {
    await translatePage(lang);
    setCurrentLang(lang);
  };

  const menu = (
    <Menu selectedKeys={[currentLang]}>
      <Menu.Item 
        key="vi" 
        onClick={() => handleLanguageChange('vi')} 
        disabled={isLoading}
      >
        🇻🇳 Tiếng Việt
      </Menu.Item>
      <Menu.Item 
        key="en" 
        onClick={() => handleLanguageChange('en')} 
        disabled={isLoading}
      >
        🇺🇸 English
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="gx-language-selector">
      <div id="google_translate_element" style={{ display: 'none' }} />
      <Dropdown overlay={menu} placement="bottomRight" disabled={isLoading}>
        <Button>
          {isLoading ? <LoadingOutlined /> : <GlobalOutlined />}
          {currentLang === 'vi' ? ' Tiếng Việt' : ' English'}
        </Button>
      </Dropdown>
    </div>
  );
};

export default LanguageSelector; 