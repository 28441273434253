import React, { useState } from "react";
import { Card, Tabs, Input, Button, Select, DatePicker } from "antd";
import styled from "styled-components";
import ReturnOrderTable from "./components/ReturnOrderTable";
import { useReturnOrderFilters } from "./hooks/useReturnOrderFilters";
import moment from "moment";

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const PAGE_ID = 322; // New page ID for Return Orders

const StyledCard = styled(Card)`
  margin: 24px;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  .ant-card-body {
    padding: 0;
  }

  @media (max-width: 768px) {
    margin: 12px;
    border-radius: 12px;
  }
`;

const FilterContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #edf2f7;

  @media (max-width: 768px) {
    padding: 16px;
    gap: 12px;
  }
`;

const SearchGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  flex: 1;
  min-width: 300px;

  .ant-select {
    min-width: 160px;

    .ant-select-selector {
      height: 40px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      border-color: #e2e8f0;
    }
  }

  .ant-input-search {
    flex: 1;
    min-width: 200px;
    max-width: 320px;

    .ant-input-wrapper {
      .ant-input {
        height: 40px;
        border-radius: 8px 0 0 8px;
        border-color: #e2e8f0;
      }
      .ant-input-search-button {
        height: 40px;
        border-radius: 0 8px 8px 0;
      }
    }
  }

  .ant-picker {
    height: 40px;
    border-radius: 8px;
    border-color: #e2e8f0;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .ant-btn {
    height: 36px;
    border-radius: 8px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    transition: all 0.2s;

    &:hover {
      transform: translateY(-1px);
    }
  }
`;

const TabsContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  border-bottom: 1px solid #f0f0f0;
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
    padding: 0 16px;

    .ant-tabs-tab {
      padding: 12px 0;
      margin: 0 16px 0 0;
      transition: all 0.3s;

      &:hover {
        color: #9f60fc;
      }

      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #9f60fc;
        font-weight: 500;
      }
    }

    .ant-tabs-ink-bar {
      background: #9f60fc;
    }
  }
`;

export default function ReturnOrderListCtrl() {
  const [activeTab, setActiveTab] = useState<string>("all");
  const [localSearchValue, setLocalSearchValue] = useState<string>("");
  const { filters, updateFilter, resetFilters, buildQueryParams, updatePagination } = useReturnOrderFilters();
  const [statusCounts, setStatusCounts] = useState({
    PENDING: 0,
    BACK_TO_STORE: 0,
    WAITING_MONEY_RETURN: 0,
    COMPLETED: 0,
    REJECTED: 0,
  });

  const searchPlaceholders: Record<string, string> = {
    orderId: "Enter Order ID",
    returnId: "Enter Return Request ID",
    product: "Enter Product Name",
    buyerName: "Enter Buyer Name",
    date: "Select date range",
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    updateFilter("status", key);
  };

  const handleSearchTypeChange = (value: string) => {
    updateFilter("searchType", value);
    updateFilter("searchValue", "");
    setLocalSearchValue("");
    if (value !== 'date') {
      updateFilter('dateRange', undefined);
    }
  };

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchValue(e.target.value);
  };

  const handleApplySearch = () => {
    updateFilter("searchValue", localSearchValue);
    const queryInput: Record<string, any> = {};

    if (localSearchValue && filters.searchType !== 'date') {
      switch (filters.searchType) {
        case "orderId":
          queryInput.orderId = localSearchValue;
          break;
        case "returnId":
          queryInput.id = localSearchValue;
          break;
      }
      updateFilter("queryInput", queryInput);
    }
  };

  const handleDateRangeChange = (dates: any) => {
    if (dates) {
      updateFilter('dateRange', [
        dates[0].startOf('day').valueOf(),
        dates[1].endOf('day').valueOf()
      ]);
    } else {
      updateFilter('dateRange', undefined);
    }
  };

  const handleReset = () => {
    resetFilters();
    setLocalSearchValue("");
    setActiveTab("all");
  };

  return (
    <StyledCard>
      <TabsContainer>
        <StyledTabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab="All" key="all" />
          <TabPane
            tab={`Pending (${statusCounts.PENDING || 0})`}
            key="PENDING"
          />
          <TabPane
            tab={`Returning (${statusCounts.BACK_TO_STORE || 0})`}
            key="BACK_TO_STORE"
          />
          <TabPane
            tab={`Pending Refund (${statusCounts.WAITING_MONEY_RETURN || 0})`}
            key="WAITING_MONEY_RETURN"
          />
          <TabPane
            tab={`Completed (${statusCounts.COMPLETED || 0})`}
            key="COMPLETED"
          />
          <TabPane
            tab={`Rejected (${statusCounts.REJECTED || 0})`}
            key="REJECTED"
          />
        </StyledTabs>
      </TabsContainer>

      <FilterContainer>
        <SearchGroup>
          <Select
            value={filters.searchType}
            onChange={handleSearchTypeChange}
            style={{ width: 160 }}
          >
            <Select.Option value="orderId">Order ID</Select.Option>
            <Select.Option value="returnId">Return Request ID</Select.Option>
            <Select.Option value="date">Request Date</Select.Option>
          </Select>

          {filters.searchType === "date" ? (
            <RangePicker
              value={filters.dateRange ? [
                moment(filters.dateRange[0]),
                moment(filters.dateRange[1])
              ] : null}
              onChange={handleDateRangeChange}
              style={{ width: 280 }}
              placeholder={["From Date", "To Date"]}
              format="DD/MM/YYYY"
            />
          ) : (
            <Input.Search
              placeholder={searchPlaceholders[filters.searchType]}
              value={localSearchValue}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^\d]/g, '');
                setLocalSearchValue(numericValue);
              }}
              onSearch={handleApplySearch}
              type="text"
              maxLength={20}
            />
          )}

          <ActionButtons>
            <Button
              type="primary"
              style={{ backgroundColor: "#9f60fc", borderColor: "#9f60fc" }}
              onClick={handleApplySearch}
            >
              Apply
            </Button>
            <Button onClick={handleReset}>Reset</Button>
          </ActionButtons>
        </SearchGroup>
      </FilterContainer>

      <ReturnOrderTable
        pageId={PAGE_ID}
        filters={filters}
        buildQueryParams={buildQueryParams}
        updatePagination={updatePagination}
        onStatusCountsUpdate={(counts) => {
          setStatusCounts(counts as any);
        }}
      />
    </StyledCard>
  );
}
