import React, { useEffect, useState } from 'react';
import { Table, Button, Rate, Image, Typography, Space, message, Switch } from 'antd';
import styled from 'styled-components';
import type { ColumnsType } from 'antd/es/table';
import { Review } from '../types';
import moment from 'moment';
import { rateService } from '@src/services/rateService';
import { helper } from '@src/controls/controlHelper';
import { CopyOutlined } from '@ant-design/icons';

const { Text, Link } = Typography;

const StyledTable = styled(Table)`
  .ant-table {
    background: #fff;
  }

  .ant-table-tbody > tr > td {
    padding: 16px;
  }
`;

const ProductInfo = styled.div`
  display: flex;
  gap: 12px;
  align-items: flex-start;

  .product-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
  }

  .product-details {
    flex: 1;
  }
`;

interface RateItem {
  id: number;
  name: string;
  star: number;
  comment: string;
  images: string[];
  orderId: number;
  createdAt: number;
  isActive: boolean;
  productInfo?: {
    id: number;
    name: string;
    images: string[];
  };
}

interface RateStatistics {
  oneStar: number;
  twoStar: number;
  threeStar: number;
  fourStar: number;
  fiveStar: number;
  averageRating: number;
  totalRatings: number;
}

interface RateResponse {
  rates: RateItem[];
  total: number;
  statistics: RateStatistics;
}

interface ReviewTableProps {
  pageId: number;
  pageInfo: any;
  filters: any;
  buildQueryParams: () => any;
  updatePagination: (page: number, pageSize: number) => void;
  onStatusCountsUpdate: (counts: any) => void;
  onRatingStatisticsUpdate: (statistics: {
    oneStar: number;
    twoStar: number;
    threeStar: number;
    fourStar: number;
    fiveStar: number;
    averageRating: number;
    totalRatings: number;
  }) => void;
}

const ReviewTable = ({
  pageId,
  pageInfo,
  filters,
  buildQueryParams,
  updatePagination,
  onStatusCountsUpdate,
  onRatingStatisticsUpdate,
}: ReviewTableProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);

  const getRatingStar = (rating: number | undefined): '1' | '2' | '3' | '4' | '5' | 'all' => {
    if (!rating) return 'all';
    return rating.toString() as '1' | '2' | '3' | '4' | '5';
  };

  const fetchData = async () => {
    try {
      if (!pageInfo) {
        return;
      }

      setLoading(true);
      
      
      const queryInput: any = {};
      
      if (filters.rating) {
        queryInput.star = filters.rating.toString();
      }

      if (filters.queryInput?.name) {
        queryInput.name = filters.queryInput.name;
      }

      if (filters.queryInput?.productName) {
        queryInput.productName = filters.queryInput.productName;
      }

      if (filters.queryInput?.orderId) {
        queryInput.orderId = Number(filters.queryInput.orderId);
      }

      if (filters.dateRange) {
        queryInput.createdAt = {
          gte: filters.dateRange[0],
          lte: filters.dateRange[1]
        };
      }

      const params = {
        skip: (filters.page - 1) * filters.pageSize,
        limit: filters.pageSize,
        queryInput: JSON.stringify(queryInput)
      };

      const response = await helper.callPageApi(pageInfo, 'find-rate', params);
      
      if (!response?.data) {
        throw new Error('No data returned from API');
      }

      const responseData = response.data;

      const rates = Array.isArray(responseData.data) ? responseData.data : [];
      const formattedData = rates.map((item) => ({
        key: item.id.toString(),
        id: item.id,
        user: {
          name: item.name || '',
          avatar: ''
        },
        product: {
          name: item.productInfo?.name || '',
          image: item.productInfo?.images?.[0] || '',
          variant: ''
        },
        rating: item.star,
        comment: item.comment || '',
        images: item.images || [],
        orderId: item.orderId?.toString() || '',
        createdAt: {
          time: item.createdAt,
          message: ''
        },
        isActive: item.isActive
      }));

      setData(formattedData);
      setTotal(responseData.count || 0);

      const pendingCount = rates.filter((item) => item.isActive).length;
      onStatusCountsUpdate({
        total: responseData.count || 0,
        pending: pendingCount,
        replied: (responseData.count || 0) - pendingCount
      });

      if (responseData.statistics) {
        onRatingStatisticsUpdate({
          oneStar: responseData.statistics.oneStar || 0,
          twoStar: responseData.statistics.twoStar || 0,
          threeStar: responseData.statistics.threeStar || 0,
          fourStar: responseData.statistics.fourStar || 0,
          fiveStar: responseData.statistics.fiveStar || 0,
          averageRating: responseData.statistics.averageRating || 0,
          totalRatings: responseData.count || 0
        });
      } else {
        onRatingStatisticsUpdate({
          oneStar: 0,
          twoStar: 0,
          threeStar: 0,
          fourStar: 0,
          fiveStar: 0,
          averageRating: 0,
          totalRatings: 0
        });
      }
    } catch (error) {
      message.error('Error loading data');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStatus = async (id: number, isActive: boolean) => {
    try {
      await helper.callPageApi(pageInfo, 'update-rate', { id, isActive });
      message.success('Status updated successfully');
      fetchData(); // Refresh data after update
    } catch (error) {
      message.error('Error updating status');
      console.error('Error updating status:', error);
    }
  };

  useEffect(() => {
    if (pageInfo) {
      fetchData();
    }
  }, [filters, pageInfo]);

  const formatTime = (timeObj: any) => {
    if (!timeObj) return '';
    if (typeof timeObj === 'string') return timeObj;
    if (timeObj.time) {
      return moment(timeObj.time).format('DD/MM/YYYY HH:mm:ss');
    }
    return '';
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Product Information',
      key: 'product',
      width: '30%',
      render: (_, record) => (
        <ProductInfo>
          <Image
            className="product-image"
            src={record.product?.image || ''}
            alt={record.product?.name || ''}
          />
          <div className="product-details">
            <Text strong>{record.product?.name || ''}</Text>
            <br />
            <Text type="secondary">{record.product?.variant || ''}</Text>
            <br />
            <Space>
              <Text type="secondary">Order ID:</Text>
              <Link href={`/order/${record.orderId || ''}`}>{record.orderId || ''}</Link>
              <Button 
                type="text" 
                icon={<CopyOutlined />}
                onClick={() => {
                  navigator.clipboard.writeText(record.orderId);
                  message.success('Order ID copied successfully');
                }}
              />
            </Space>
          </div>
        </ProductInfo>
      ),
    },
    {
      title: 'Buyer Review',
      key: 'review',
      width: '40%',
      render: (_, record) => (
        <Space direction="vertical" size="small">
          <Space>
            <Text>{record.user?.name || ''}</Text>
            <Rate disabled defaultValue={record.rating || 0} />
          </Space>
          <Text>{record.comment || ''}</Text>
          {record.images && record.images.length > 0 && (
            <Space size={8}>
              {record.images.map((image: string, index: number) => (
                <Image
                  key={index}
                  width={64}
                  height={64}
                  src={image}
                  style={{ objectFit: 'cover', borderRadius: 4 }}
                  preview={{
                    visible: false
                  }}
                />
              ))}
            </Space>
          )}
          <Text type="secondary">{formatTime(record.createdAt)}</Text>
        </Space>
      ),
    },
    {
      title: 'Actions',
      key: 'action',
      width: '15%',
      render: (_, record) => (
        <Space>
          <Button type="primary" disabled={!record.isActive}>
            Reply
          </Button>
          <Switch
            checked={record.isActive}
            onChange={(checked) => handleUpdateStatus(record.id, checked)}
          />
        </Space>
      ),
    },
  ];

  return (
    <StyledTable
      columns={columns}
      rowKey="key"
      dataSource={data}
      loading={loading}
      pagination={{
        total: total,
        pageSize: filters.pageSize,
        current: filters.page,
        showSizeChanger: true,
        showQuickJumper: true,
      }}
      onChange={(pagination) => {
        updatePagination(pagination.current || 1, pagination.pageSize || 10);
      }}
    />
  );
};

export default ReviewTable;